// Time.js
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Time.css";
import img1 from "./IMG/1.jpeg";
import img2 from "./IMG/2.jpeg";
import img3 from "./IMG/3.jpeg";
import img4 from "./IMG/4.jpeg";

const produtos = [
  { id: 1, src: img1, nome: "Vestidos", categoria: "vestido" },
  { id: 2, src: img2, nome: "Conjuntos", categoria: "conjunto" },
  { id: 3, src: img3, nome: "Saias", categoria: "saia" },
  { id: 4, src: img4, nome: "Croppeds", categoria: "cropped" },
];

const Time = () => {
  const navigate = useNavigate();

  const handleClick = (categoria) => {
    navigate(`/pesquisa?query=${categoria}`);
  };

  return (
    <div className="time-carousel-container">
      <div className="time-carousel">
        {produtos.map((produto) => (
          <div key={produto.id} className="produto-item" onClick={() => handleClick(produto.categoria)}>
            <img
              src={produto.src}
              alt={produto.nome}
              className="time-escudo"
            />
            <p>{produto.nome}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Time;