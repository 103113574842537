import React, { useEffect } from "react"; // Adicione o useEffect aqui
import { Link, useLocation } from "react-router-dom";
import { normalizeString } from "./App"; // Certifique-se de que a função normalizeString está sendo importada
import "./ProdutosFiltrados.css";

const ProdutosFiltrados = ({ products }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const time = query.get("query") || ""; // Valor padrão se 'query' não for encontrado

  useEffect(() => {
    // Rola para o topo assim que o componente for montado ou quando o filtro for alterado
    window.scrollTo(0, 0);
  }, [time]);  // O useEffect depende da query 'time', para rolar sempre que mudar.

  // Verifica se 'products' é um array válido
  if (!products || !Array.isArray(products)) {
    return <p>Não há produtos disponíveis.</p>;
  }

  // Filtra os produtos pelo nome do time de forma insensível a maiúsculas/minúsculas e permitindo correspondência parcial
  const filteredProducts = products.filter((product) =>
    time && product.name.toLowerCase().includes(time.toLowerCase()) // Verifica se 'time' existe antes de usar
  );

  return (
    <div className="produtos-filtrados">
      <h1>Produtos de {time || "todos"}</h1> {/* Mostra "todos" caso 'time' esteja vazio */}
      <div className="produtos-grid">
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product) => (
            <div key={product.id} className="produto-card">
              <Link to={`/${normalizeString(product.name)}`}>
                {/* Imagem com Lazyload */}
                <img
                  data-src={product.imageUrls[0]} // Usando o link real da imagem
                  alt={product.name}
                  className="produto-imagem lazyload" // Classe para lazyload
                  src="placeholder.png" // Imagem de placeholder enquanto a imagem real não carrega
                />
                <h2>{product.name}</h2>
                <p>R$ {product.price}</p>
              </Link>
            </div>
          ))
        ) : (
          <p>Nenhum produto encontrado para este time.</p>
        )}
      </div>
    </div>
  );
};

export default ProdutosFiltrados;
