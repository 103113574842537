import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { HiOutlineMenuAlt3 } from 'react-icons/hi';
import { MdChevronRight } from 'react-icons/md'; // Ícone da seta
import './Categorias.css';

const Categorias = ({ categories }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <div className="categorias">
      <button className="menu-icon" onClick={toggleMenu}>
        <HiOutlineMenuAlt3 className="icon" />
      </button>

      {isMenuOpen && (
        <div className="categorias-dropdown" ref={dropdownRef}>
          <ul>
            <li>
              <Link to="/meuspedidos" onClick={toggleMenu}>
                Meus Pedidos <MdChevronRight />
              </Link>
            </li>
            {categories.map((category) => (
              <li key={category}>
                <Link to={`/${category.toLowerCase()}`} onClick={toggleMenu}>
                  {category} <MdChevronRight />
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Categorias;
